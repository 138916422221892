// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../node_modules/remixicon/fonts/remixicon.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap);"]);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --primary-color: #111317;
    --primary-color-light: #1f2125;
    --primary-color-extra-light: #35373b;
    --secondary-color: #f9ac54;
    --secondary-color-dark: #d79447;
    --text-light: #d1d5db;
    --white: #ffffff;
    --max-width: 1200px;
  }

.privacy__container {
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
}

.privacy__content h1 {
    color: var(--secondary-color);
}

.privacy__content h2 {
    color: var(--secondary-color);
    font-size: large;
}

.privacy__content h3 {
    color: var(--secondary-color);
    font-size: medium;
}

.privacy__content p {
    color: var(--white);
}

.privacy__content li {
    color: var(--white);
}`, "",{"version":3,"sources":["webpack://./src/styles/privacypolicy.css"],"names":[],"mappings":"AAIA;IACI,wBAAwB;IACxB,8BAA8B;IAC9B,oCAAoC;IACpC,0BAA0B;IAC1B,+BAA+B;IAC/B,qBAAqB;IACrB,gBAAgB;IAChB,mBAAmB;EACrB;;AAEF;IACI,aAAa;IACb,8BAA8B;IAC9B,gBAAgB;AACpB;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,6BAA6B;IAC7B,gBAAgB;AACpB;;AAEA;IACI,6BAA6B;IAC7B,iBAAiB;AACrB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap\");\n@import 'remixicon/fonts/remixicon.css';\n\n\n:root {\n    --primary-color: #111317;\n    --primary-color-light: #1f2125;\n    --primary-color-extra-light: #35373b;\n    --secondary-color: #f9ac54;\n    --secondary-color-dark: #d79447;\n    --text-light: #d1d5db;\n    --white: #ffffff;\n    --max-width: 1200px;\n  }\n\n.privacy__container {\n    padding: 20px;\n    font-family: Arial, sans-serif;\n    line-height: 1.6;\n}\n\n.privacy__content h1 {\n    color: var(--secondary-color);\n}\n\n.privacy__content h2 {\n    color: var(--secondary-color);\n    font-size: large;\n}\n\n.privacy__content h3 {\n    color: var(--secondary-color);\n    font-size: medium;\n}\n\n.privacy__content p {\n    color: var(--white);\n}\n\n.privacy__content li {\n    color: var(--white);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
